<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                    <b-tbody>
                      <b-tr>
                        <b-td style="width:20%">{{ $t('research_manage.organization_type') }}</b-td>
                        <b-td class="text-center" style="width:3%">:</b-td>
                        <b-td colspan="4" style="width:20%"> {{ ($i18n.locale==='bn') ? formData.org_type_bn : formData.org_type_en }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td style="width:20%">{{ $t('research_manage.org_or_univ_name') }}</b-td>
                        <b-td class="text-center" style="width:3%">:</b-td>
                        <b-td colspan="4" style="width:20%">
                          {{ ($i18n.locale==='bn') ? formData.component_org_name_bn : formData.component_org_name }}
                        </b-td>
                      </b-tr>
                      <b-tr v-if="formData.org_type === 2">
                        <b-td style="width:20%">{{ $t('research_manage.component_organization_address') }}</b-td>
                        <b-td class="text-center" style="width:3%">:</b-td>
                        <b-td colspan="4" style="width:10%">{{ ($i18n.locale==='bn') ? formData.component_org_address_bn : formData.component_org_address }}</b-td>
                      </b-tr>
                      <b-tr v-if="formData.org_type === 2">
                        <b-td style="width:20%">{{ $t('research_manage.component_organization_email') }}</b-td>
                        <b-td class="text-center" style="width:3%">:</b-td>
                        <b-td colspan="4" style="width:10%">{{ formData.component_org_email }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td style="width:20%">{{ $t('research_manage.coordinator_name') }}</b-td>
                        <b-td class="text-center" style="width:3%">:</b-td>
                        <b-td style="width:10%">{{ ($i18n.locale==='bn') ? formData.coord_name_bn : formData.coord_name }}</b-td>
                        <b-td style="width:20%">{{ $t('research_manage.coordinator_designation') }}</b-td>
                        <b-td class="text-center" style="width:3%">:</b-td>
                        <b-td style="width:10%">{{ ($i18n.locale==='bn') ? formData.coord_designation_bn : formData.coord_designation }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td style="width:20%">{{ $t('research_manage.coordinator_mobile') }}</b-td>
                        <b-td class="text-center" style="width:3%">:</b-td>
                        <b-td style="width:10%">{{ $n('0') + $n(formData.coord_mobile_no, { useGrouping: false }) }}</b-td>
                        <b-td style="width:20%">{{ $t('globalTrans.phone_no') }}</b-td>
                        <b-td class="text-center" style="width:3%">:</b-td>
                        <b-td style="width:10%">{{ $n('0') + $n(formData.coord_phone_no, { useGrouping: false }) }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td style="width:20%">{{ $t('globalTrans.email') }}</b-td>
                        <b-td class="text-center" style="width:3%">:</b-td>
                        <b-td colspan="4" style="width:10%">{{ formData.coord_email }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
  .dam-form .is-valid, .is-invalid {
    background-image: none !important;
    padding-right: 0px !important;
  }
</style>
<script>
import { core } from '@/config/pluginInit'

export default {
  props: ['id'],
  components: {
  },
  data () {
    return {
      loading: false,
      formData: {}
    }
  },
  created () {
    if (this.id) {
        const tmp = this.getFormData(this.id)
        this.formData = tmp
    }
  },
  mounted () {
    core.index()
  },
  watch: {
  },
  methods: {
    getFormData (Id) {
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(Id))
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
